<template>
  <van-popup
    v-model="showPopup"
    position="right"
    overlay-class="overlay"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="app-container">
      <div class="top-menu">
        <div class="icon-box">
          <van-icon name="jue-arrowleft" size="30" @click="showPopup = false" />
        </div>
        <div class="top-right">
          <span class="realname">
            {{ title }}
          </span>
        </div>
      </div>
      <div class="group">
        <van-cell-group>
          <template v-for="(item, index) in dataList">
            <van-cell
              :key="index"
              :title="item.realname"
              center
              @click="showDetail(item.client_id)"
            >
              <template #title>
                <van-image
                  class="wechat-avatar"
                  round
                  width="12"
                  height="12"
                  :src="item.wechat_avatar"
                />
                <span class="custom-title"
                  >{{ base64(item.wechat_nickname) }}
                  <span class="realname" v-if="item.realname"
                    >({{ item.realname }})</span
                  ></span
                >
              </template>

              <div class="items">
                <div class="item">
                  转发渠道: {{ item.channel === 1 ? '微信好友' : '朋友圈' }}
                </div>
                <div class="item">转发时间: {{ item.created_at }}</div>
              </div>
            </van-cell>
          </template>
        </van-cell-group>
      </div>
    </div>
  </van-popup>
</template>
<script>
export default {
  data () {
    return {
      showPopup: false,
      title: '',
      dataList: []
    }
  },
  methods: {
    show (query, title) {
      this.title = title
      this.$axios.post('/client_article/forward_lists', query).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.list
          this.showPopup = true
        }
      })
    },
    base64 (str) {
      const Base64 = require('js-base64').Base64
      return Base64.decode(str)
    }
  }
}
</script>
<style lang="scss" scoped>
.overlay{
  z-index: 99999999;
}
.top-menu {
  padding: 20px 0;
  display: flex;
  align-items: center;
  .icon-box {
    flex: 1;
  }
  .top-right {
    display: flex;
    align-items: center;
    .realname {
      font-size: 12px;
      margin-right: 5px;
    }
  }
}
.items {
  font-size: 12px;
}
.group {
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.custom-title {
  .realname {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>