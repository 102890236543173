<template>
  <div class="app-container">
    <SearchDate :query="query" />
    <h2 class="h2">动态统计</h2>

    <van-cell-group style="margin-bottom: 20px">
      <template v-for="(item, index) in dataList">
        <van-cell
          is-link
          :key="index"
          :title="item.title"
          :label="item.created_at"
          center
          @click="showDetail(item, item.title)"
        >
          <div class="items">
            <div class="item">转发量: {{ item.forward_count }}</div>
            <div class="item">下线用户量: {{ item.under_count }}</div>
            <!-- <div class="item">近期转发时间: {{ item.last_forward_at || '-' }}</div> -->
            <div class="item">总阅读量: {{ item.view_count }}</div>
          </div>
        </van-cell>
      </template>
    </van-cell-group>

    <van-cell-group style="margin-bottom: 20px; padding: 10px 0; height: 365px">
      <VeHistogram :data="dataChart" />
    </van-cell-group>

    <Detail ref="detail" />

    <Tabbar :json="{ active: 2 }" />
  </div>
</template>
<script>
import Detail from './components/detail'
import SearchDate from '@/components/common/searchDate'
import Tabbar from '@/components/common/tabbar.vue'
import VeHistogram from 'v-charts/lib/histogram.common'
import { Notify } from 'vant'
export default {
  components: {
    SearchDate,
    Tabbar,
    VeHistogram,
    Detail
  },
  data () {
    return {
      loading: false,
      finished: false,
      dataList: [],
      dataChart: {},
      total: 0,
      query: {

      },
      detail: {},
      show: false
    }
  },
  methods: {
    getDataList () {
      this.getDataChart()
      this.$axios.post('/statistic_article/data1', this.query).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.list
        }
      })
    },
    getDataChart () {
      this.$axios.post('/statistic_article/chart2', this.query).then(res => {
        if (res.code === 200) {
          this.dataChart = res.data.list
        }
      })
    },
    showDetail (item, title) {
      if(item.forward_count === 0){
        Notify({ type: 'danger', message: '没有转发记录' })
        return
      }
      let query = {
        create_date_begin: this.query.begin_date,
        create_date_end: this.query.end_date,
        article_id: item.article_id,
        count: 1000
      }
      this.$refs.detail.show(query, title)
    }
  }
}
</script>
<style lang="scss" scoped>
.h2 {
  font-size: 18px;
  padding: 10px 0;
}
.wechat-avatar {
  margin-right: 5px;
}
.items {
  font-size: 12px;
}
</style>